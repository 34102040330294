<template>
  <div class="assessment vh-100">
    <v-container class="py-0 px-0 pb-10" fluid>
      <v-alert
        v-if="!$online"
        dense
        tile
        color="gray"
        icon="mdi-alert-circle-outline"
        class="alert-offline text-headline"
      >
        {{ $t("t_no_connection") }}
      </v-alert>
      <v-card flat tile>
        <v-card-text class="d-flex pb-0">
          <p class="text-h6 mb-0">Risk Plan for Henderson Mine Blasting</p>
        </v-card-text>
      </v-card>
      <v-list three-line class="pt-0">
        <div v-for="(item, itemIndex) in items" :key="itemIndex">
          <!-- <v-subheader v-if="item.header" :key="item.header" v-text="item.header"></v-subheader> -->
          <v-list-item :key="item.title" class="pt-3">
            <v-list-item-avatar>
              <!-- <v-checkbox v-model="item.completed" :disabled="true"></v-checkbox> -->
              <v-icon v-if="item.completed" color="primary" large>mdi-check-circle</v-icon>
              <v-icon v-else color="grey darken-2" large>mdi-circle-outline</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="overline" style="line-height: 12px">Step {{ item.step }}</v-list-item-title>
              <v-list-item-title v-html="item.title"></v-list-item-title>
              <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-group
            class="list-hazards mb-0 pb-0"
            v-if="!item.divider"
            :value="item.expand"
            no-action
            style="border-bottom: #ccc 1px solid"
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-subtitle
                  ><div class="pl-14 orange--text text--darken-2">
                    Step {{ item.step }} Review
                  </div></v-list-item-subtitle
                >
              </v-list-item-content>
            </template>
            <div v-if="item.hazards" class="pt-4 px-7">
              <div v-for="(hazard, hazardIndex) in item.hazards" :key="'hazardItem-' + hazardIndex" style="color: #fff">
                <div v-for="(type, subTypeIndex) in hazard.subTypes" :key="'typedItem-' + subTypeIndex">
                  <span class="text-capitalize">
                    <v-icon color="orange darken-2 mr-1" style="font-size: 20px; margin-top: -2px"
                      >mdi-alert-box</v-icon
                    >
                    {{ type.name }} : {{ hazard.type }}</span
                  >
                  <div class="ml-6 mt-2 mr-6">
                    <v-progress-linear
                      rounded
                      striped
                      class="my-2"
                      color="light-green darken-3"
                      :value="Math.ceil((type.likelihood / 5) * 100)"
                      height="15"
                    >
                      <span class="overline" style="background: transparent">Likelihood</span>
                    </v-progress-linear>

                    <v-progress-linear
                      rounded
                      striped
                      class="my-2"
                      color="orange darken-4"
                      :value="Math.ceil((type.impact / 5) * 100)"
                      height="15"
                    >
                      <span class="overline">Impact</span>
                    </v-progress-linear>
                    <label class="overline">Risk Controls</label>
                    <div class="mb-3">
                      {{ type.controls }}
                    </div>
                    <label class="overline">Actions to reduce risk</label>
                    <div>{{ type.actions }}</div>
                    <v-checkbox
                      v-model="hazard.checked"
                      @click="checkChecks(itemIndex + 1)"
                      hide-details
                      class="accept-check"
                    >
                      <template v-slot:label>
                        <span class="font-weight-bold">I accept and recognize</span>
                      </template>
                    </v-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </v-list-group>
          <v-divider></v-divider>
        </div>
      </v-list>
    </v-container>
  </div>
</template>

<script>
import Helpers from "@/mixins/helpers";
export default {
  name: "AssessmentReview",
  mixins: [Helpers],
  computed: {},
  watch: {},
  data: () => ({
    open: ["Hazards"],
    cruds: [
      ["Create", "mdi-plus-outline"],
      ["Read", "mdi-file-outline"],
      ["Update", "mdi-update"],
      ["Delete", "mdi-delete"],
    ],
    ///

    items: [
      {
        step: 1,
        avatar: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
        title: "Conduct safety checks",
        subtitle: `Before beginning any work, conduct safety checks on all equipment and personnel to ensure everyone is prepared and equipped for safe blasting operations.`,
        completed: false,
        expand: true,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
      {
        step: 2,
        avatar: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
        title: "Drill blast holes",
        subtitle: ` Use a drill to bore holes into the rock, following the planned blast pattern.`,
        completed: false,
        expand: false,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
      {
        step: 3,
        avatar: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
        title: "Load explosives",
        subtitle: "Load the explosives into the drilled blast holes, using proper safety procedures and equipment.",
        completed: false,
        expand: false,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
      {
        step: 4,
        avatar: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
        title: "Connect detonator",
        subtitle: "Connect the detonator wires to the explosives and ensure proper wiring is in place.",
        completed: false,
        expand: false,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
      {
        step: 5,
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Evacuate the area",
        subtitle:
          "Before detonating the explosives, ensure that all personnel and equipment are safely evacuated from the blast zone.",
        completed: false,
        expand: false,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
      {
        step: 6,
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Detonate explosives",
        subtitle:
          "Detonate the explosives using an electronic detonator, ensuring that the blast is controlled and directed according to the planned pattern.",
        completed: false,
        expand: false,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
      {
        step: 6,
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Clear the site",
        subtitle: "Clear debris from the blast site and dispose of waste rock and other materials in designated areas.",
        completed: false,
        expand: false,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
      {
        step: 8,
        avatar: "https://cdn.vuetifyjs.com/images/lists/5.jpg",
        title: "Document the blast",
        subtitle:
          "Document the blast, including the date and time, blast pattern, amount of explosives used, and any other relevant information.",
        completed: false,
        expand: false,
        hazards: [
          {
            stepCount: 0,
            checked: false,
            type: "material explosive",
            subTypes: [
              { name: "nitroglycerine", impact: 4, likelihood: 3, actions: "Walk steady", controls: "Acceptance" },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "noise",
            subTypes: [
              {
                name: "noise (impact)",
                impact: 2,
                likelihood: 5,
                actions: "Ear plugs or head gear",
                controls: "Contingency planning",
              },
            ],
          },
          {
            stepCount: 0,
            checked: false,
            type: "thermal",
            subTypes: [
              { name: "explosion - mine", impact: 4, likelihood: 5, actions: "Use your brain", controls: "Monitoring" },
            ],
          },
        ],
      },
    ],
  }),

  methods: {
    appRefresh() {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
    showAsset(type) {
      this.asset = type;
      if (type == "video") {
        this.$refs.videoPlayer.play();
      }
    },
    checkChecks(itemIndex) {
      if (
        this.items.find((i) => i.step == itemIndex).hazards.filter((h) => h.checked).length ==
        this.items.find((i) => i.step == itemIndex).hazards.length
      ) {
        this.items.find((i) => i.step == itemIndex).expand = false;
        this.items.find((i) => i.step == itemIndex).completed = true;
        this.items.find((i) => i.step == itemIndex + 1).expand = true;
      }
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.v-list-group.list-hazards {
  .v-list-item {
    min-height: 20px;
    background: #333;
    font-weight: bold;
    margin-bottom: 0px;
    color: #468ffb !important;
  }
}

.accept-check {
  border: 1px solid #333;
  padding: 10px;
  margin: 8px 0;
  background: #131313;
}
</style>
